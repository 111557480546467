<template>
  <div>
        <div class="header" style="border-bottom:1px solid #f5f5f5">
             <ul class="left_menu">
                <div class="item"> 
                    <li class=" a" @click="go" style="    cursor: pointer"> <i class="el-icon-back"></i> 返回</li>
                 
                </div>
                
               
             </ul>
        </div>
             <!-- <div class="search" style="padding:7.5px 40px; display:flex;font-size:14px;justify-content: space-between;align-items: center;">
            <div style=" display:flex;">
                <p style="font-size: 22px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #000000;
line-height: 30px;">消息中心</p>
                 <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">关键字</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input1">
                </el-input>
            </div>
             <div class="block" style="margin-right:15px">
                <span class="demonstration" style="margin-right:5px">发布时间</span>
                <el-date-picker
                style="width:150px;"
                v-model="value1"
                type="date"
                placeholder="选择日期">
                </el-date-picker>
            </div>
            <div>
                <span class="demonstration" style="margin-right:5px;">公告类型</span>
                    <el-select v-model="select" slot="prepend" placeholder="请选择" style="width:150px">
                    <el-option label="普通公告" value="1"></el-option>
                    <el-option label="重要公告" value="2"></el-option>
                  
                 </el-select>
             </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div> -->
        <div class="content" style="padding:10px 40px;background:#f5f5f5">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
   
    <el-tab-pane label="公告消息" name="second">
        <!-- <p style="font-size: 16px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #000000;
line-height: 22px;">公告消息</p> -->
 <el-col :span="24" style="margin-top:20px" >  <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%;text-align:center">
    
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="subject"
      label="公告标题"
      width="100">
    </el-table-column>
    <el-table-column
      label="公告类型"
      prop="type" 
      width="100">
    
    </el-table-column>

       <el-table-column
      label="用户ID"
    prop="name" 
      
      width="100">
    
    </el-table-column>
      

    
      <el-table-column
        prop="content" 
      label="内容" 
           :show-overflow-tooltip="true" 
      width="300">
   
    </el-table-column>

  <el-table-column
        prop="name" 
      label="发布人"
      width="110">
   
    </el-table-column>
        <el-table-column
        prop="create_time" 
      label="创建时间"
           :show-overflow-tooltip="true" 
      width="150">
   
    </el-table-column>

    
        <el-table-column
        prop="effect_time" 
      label="发布时间"
      :show-overflow-tooltip="true" 
   >
   
    </el-table-column>  

    
        <el-table-column
        prop="isread" 
      label="状态"
      :show-overflow-tooltip="true" 
   >
   
    </el-table-column>



   <el-table-column
     
      label="操作"
        :show-overflow-tooltip="true" 
   >
     <template slot-scope="scope">
        <el-button
          size="mini"
         
           @click="handleEdit(scope.$index, scope.row)">设为已读</el-button>
          
     </template>
    </el-table-column>
  </el-table>

   <div class="block">

      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.page_index"
      :page-sizes="pagination.page_sizes"
      :page-size="pagination.page_size"
      :layout="pagination.layout"
      :total='pagination.total'
      style="margin-top:20px">
    </el-pagination>
  </div>
</el-col>

    </el-tab-pane>
    <!-- <el-tab-pane label="系统操作记录" name="third">
        <p style="font-size: 16px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #000000;
line-height: 22px;">系统操作记录</p>
<el-col :span="24" style="margin-top:20px" >  <el-table
      :border="true"
    :data="tableData1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%">
    
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="序号"
      width="100">
    </el-table-column>
    <el-table-column
      label="时间"
      prop="time" 
      width="180">
    
    </el-table-column>

       <el-table-column
      label="编号"
    prop="code" 
      
      width="100">
    
    </el-table-column>
      

    
      <el-table-column
        prop="log" 
      label="操作记录" 
           :show-overflow-tooltip="true" 
      width="300">
   
    </el-table-column>

 
  </el-table>
</el-col>
    </el-tab-pane> -->

  </el-tabs>
        </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
                pagination:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
         activeName: 'second',
         tableData:'',
           tableData1:''
        }
    },
    methods:{
         handleSizeChange(val) {
this.pagination.page_size=val
  this.$axios.get("/s/backend/message/my_message_list", {
        params: {
       'accessToken':localStorage.getItem('token'),
       page :this.pagination.page_index,
       limit :val,
      }
        })

     .then(res=>{
     this.tableData= res.data.data
      
        })
        .catch(function(){
           
        })

            
                 
      },
      handleCurrentChange(val) {
       this.pagination.page_index=val
     


      this.$axios.get("/s/backend/message/my_message_list", {
        params: {
       'accessToken':localStorage.getItem('token'),
       page :val,
      limit :this.pagination.page_size,
      }
        })

     .then(res=>{
    
          this.tableData= res.data.data
        })
        .catch(function(){
           
        })

        
      },
        handleEdit(index,row){
                  this.$axios({
                                        method: "post",
                                        url: "/s/backend/message/change_read",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:{
                                             "id": row.id
                                        },
                                        }) .then(res=>{
                                         this.$axios.get("/s/backend/message/my_message_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
            'page':'1',
            'limit':'100',

}
})
  .then(res=>{
         
           this.tableData= res.data.data
        })
        .catch(function(){
           
        })
                                            this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                        })
        .catch(function(){
           
        })  
        },
        go(){
              this.$router.go(-1)
        }
    },
    mounted(){
         this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')
   

}
})
  .then(res=>{
         
        
        })
        .catch(function(){
           
        })

     
            this.$axios.get("/s/backend/message/log", {
　  　params: { 'accessToken':localStorage.getItem('token'),
            'page':'1',
            'limit':'100',

}
})
  .then(res=>{
         
           this.tableData1= res.data.data
        })
        .catch(function(){
           
        })



         this.$axios.get("/s/backend/message/my_message_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
            'page':'1',
            'limit':'10',

}
})
  .then(res=>{
            this.pagination.total = res.data.count
           this.tableData= res.data.data
        })
        .catch(function(){
           
        })

        
    }
}
</script>

<style scoped>
     .header{
         min-height: 57px;
  font-size: 16px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
    }
        .header .left_menu .item .a{
            margin-right: 60px;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;
           }

       .header .active{
            color: #0034FF;
            position:relative;
       
        }
            .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-80%;
    height: 2px;
    width:30px;
    background: #0034FF;
            }
    .header .active a{
          color: #0034FF !important;
    }
    /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    background: #409EFF;
    color: #fff;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
/deep/.el-table th > .cell {
    text-align: center;
}
 
/deep/.el-table .cell {
    text-align: center;
}   
</style>